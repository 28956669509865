import React, { useState } from "react";
import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";

const NavBar = styled.nav`
  font-weight: 600;
  button {
    font-size: 13px;
    font-weight: 600;
    font-family: "Circular Std";
    width: 65px;
    height: auto;
    padding: 5px -0px 5px 0px;
    color: black;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  .shows {
    overflow-y: scroll;
    position: absolute;
    width: 65px;
    margin-top: 10px;
    padding-left: 8px;
    padding-top: 8px;
    height: auto;
    background: white;
    border: 1px solid gray;
    border-radius: 4px;
  }
  p {
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Currency = () => {
  const currentCurr = localStorage.getItem("currentcy") || "NGN";
  const [showCurrency, setShowCurrency] = useState(false);

  const handelCurrentcy = () => {
    setShowCurrency(!showCurrency);
  };
  const handelUSD = () => {
    localStorage.setItem("currentcy", "USD");
    window.location.reload();
  };
  const handelEUR = () => {
    localStorage.setItem("currentcy", "EUR");
    window.location.reload();
  };
  const handelNGN = () => {
    localStorage.setItem("currentcy", "NGN");
    window.location.reload();
  };
  return (
    <NavBar>
      <button onClick={handelCurrentcy}>
        {currentCurr} <IoIosArrowDown />
      </button>
      {showCurrency && (
        <div className="shows">
          <p onClick={handelUSD}>USD</p>
          <p onClick={handelNGN}>NGN</p>
          <p onClick={handelEUR}>EUR</p>
        </div>
      )}
    </NavBar>
  );
};

export default Currency;
