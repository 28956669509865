import { createSlice } from "@reduxjs/toolkit";

//U3 PICTURES
import A4one from "../../image/A4/A4 large 13.jpg";
import A4Two from "../../image/A4/A4 large 15.jpg";
import A4Three from "../../image/A4/A4 large 12.jpg";
import A4Four from "../../image/A4/A4 large 11.jpg";
import A4Five from "../../image/A4/A4 large 10.jpg";
import A4Six from "../../image/A4/A4 large 6.jpg";
import A4Seven from "../../image/A4/A4 large 7.jpg";
import A4Eight from "../../image/A4/A4 large 8.jpg";
import A4Nine from "../../image/A4/A4 large 9.jpg";
import A4Ten from "../../image/A4/A4 large 5.jpg";
import A4Eleven from "../../image/A4/A4 large 4.jpg";
import A4Twelve from "../../image/A4/A4 large 3.jpg";
import A4Thirteen from "../../image/A4/A4 large 1.jpg";
import A4Forteen from "../../image/A4/A4 large 2.jpg";
//U3 PICTURES
import U3one from "../../image/U3/White_Cloud_large_1.jpg";
import U3Two from "../../image/U3/White_Cloud_large_2.jpg";
import U3Three from "../../image/U3/White_Cloud_large_3.jpg";
import U3Four from "../../image/U3/White_Cloud_large_4.jpg";
import U3Five from "../../image/U3/White_Cloud_large_5.jpg";
import U3Six from "../../image/U3/White_Cloud_large_6.jpg";
import U3Seven from "../../image/U3/White_Cloud_large_7.jpg";
import U3Eight from "../../image/U3/White_Cloud_large_8.jpg";
import U3Nine from "../../image/U3/White_Cloud_large_9.jpg";
import U3Ten from "../../image/U3/White_Cloud_large_10.jpg";
import U3Eleven from "../../image/U3/White_Cloud_large_11.jpg";
import U3Twelve from "../../image/U3/White_Cloud_large_12.jpg";
import U3Thirteen from "../../image/U3/White_Cloud_large_13.jpg";
import U3Forteen from "../../image/U3/White_Cloud_large_15.jpg";
//U7 PICTURES
import U7one from "../../image/U7/Sizzling_bliss_large_1.jpg";
import U7Two from "../../image/U7/Sizzling_bliss_large_2.jpg";
import U7Three from "../../image/U7/Sizzling_bliss_large_3.jpg";
import U7Four from "../../image/U7/Sizzling_bliss_large_4.jpg";
import U7Seven from "../../image/U7/Sizzling_bliss_large_7.jpg";
import U7Eight from "../../image/U7/Sizzling_bliss_large_8.jpg";
//U5 PICTURES
import U5one from "../../image/U5/Diamond_Edge_large_1.jpg";
import U5Two from "../../image/U5/Diamond_Edge_large_2.jpg";
import U5Three from "../../image/U5/Diamond_Edge_large_6.jpg";
import U5Four from "../../image/U5/Diamond_Edge_large_4.jpg";
import U5Five from "../../image/U5/Diamond_Edge_large_5.jpg";
import U5Six from "../../image/U5/Diamond_Edge_large_3.jpg";
import U5Seven from "../../image/U5/Diamond_Edge_large_7.jpg";
import U5Eight from "../../image/U5/Diamond_Edge_large_8.jpg";
import U5Nine from "../../image/U5/Diamond_Edge_large_9.jpg";
import U5Ten from "../../image/U5/Diamond_Edge_large_10.jpg";
import U5Eleven from "../../image/U5/Diamond_Edge_large_11.jpg";
import U5Twelve from "../../image/U5/Diamond_Edge_large_12.jpg";
import U5Thirteen from "../../image/U5/Diamond_Edge_large_13.jpg";
import U5Forteen from "../../image/U5/Diamond_Edge_large_14.jpg";
//U4 PICTURES
import U4one from "../../image/U4/Grandeur_large_7.jpg";
import U4Two from "../../image/U4/Grandeur_large_2.jpg";
import U4Three from "../../image/U4/Grandeur_large_3.jpg";
import U4Four from "../../image/U4/Grandeur_large_4.jpg";
import U4Five from "../../image/U4/Grandeur_large_5.jpg";
import U4Six from "../../image/U4/Grandeur_large_10.jpg";
import U4Seven from "../../image/U4/Grandeur_large_1.jpg";
import U4Eight from "../../image/U4/Grandeur_large_8.jpg";
import U4Nine from "../../image/U4/Grandeur_large_9.jpg";
import U4Ten from "../../image/U4/Grandeur_large_6.jpg";
import U4Eleven from "../../image/U4/Grandeur_large_11.jpg";
import U4Twelve from "../../image/U4/Grandeur_large_12.jpg";
import U4Thirteen from "../../image/U4/Grandeur_large_13.jpg";
import U4Forteen from "../../image/U4/Grandeur_large_14.jpg";
//U8 PICTURES
import U8one from "../../image/U8/The_Nest_large_1.jpg";
import U8Two from "../../image/U8/The_Nest_large_14.jpg";
import U8Three from "../../image/U8/The_Nest_large_3.jpg";
import U8Four from "../../image/U8/The_Nest_large_4.jpg";
import U8Five from "../../image/U8/The_Nest_large_5.jpg";
import U8Six from "../../image/U8/The_Nest_large_6.jpg";
import U8Seven from "../../image/U8/The_Nest_large_7.jpg";
import U8Eight from "../../image/U8/The_Nest_large_8.jpg";
import U8Nine from "../../image/U8/The_Nest_large_9.jpg";
import U8Ten from "../../image/U8/The_Nest_large_10.jpg";
import U8Eleven from "../../image/U8/The_Nest_large_11.jpg";
import U8Twelve from "../../image/U8/The_Nest_large_12.jpg";
import U8Thirteen from "../../image/U8/The_Nest_large_13.jpg";
import U8Forteen from "../../image/U8/The_Nest_large_2.jpg";
//C4 PICTURES
import C4ones from "../../image/C4/Topaz_partment_9.jpg";
import C4Twos from "../../image/C4/Topaz_partment_6.jpg";
import C4Threes from "../../image/C4/Topaz_partment_3.jpg";
import C4Fours from "../../image/C4/Topaz_partment_4.jpg";
import C4Fives from "../../image/C4/Topaz_partment_5.jpg";
import C4Sixs from "../../image/C4/Topaz_partment_2.jpg";
import C4Sevens from "../../image/C4/Topaz_partment_7.jpg";
import C4Eights from "../../image/C4/Topaz_partment_8.jpg";
import C4Nines from "../../image/C4/Topaz_partment_1.jpg";
import C4Tens from "../../image/C4/Topaz_partment_10.jpg";
import C4Elevens from "../../image/C4/Topaz_partment_11.jpg";

//Mother Day Picture
import MotherOne from "../../image/event/mothers/motherOne.jpeg";
import MotherTWe from "../../image/event/mothers/motherTwo.jpeg";
import MotherThree from "../../image/event/mothers/motherThree.jpeg";
import MotherFour from "../../image/event/mothers/motherFour.jpeg";
import MotherFive from "../../image/event/mothers/motherFive.jpeg";
import MotherSix from "../../image/event/mothers/motherSix.jpeg";
import MotherSeven from "../../image/event/mothers/motherSeven.jpeg";
import MotherEight from "../../image/event/mothers/motherEight.jpeg";
import MotherNine from "../../image/event/mothers/motherNine.jpeg";
import MotherTen from "../../image/event/mothers/motherTen.jpeg";
import MotherEleven from "../../image/event/mothers/motherEleven.jpeg";
import MotherTwelve from "../../image/event/mothers/motherTwelve.jpeg";
import Mother13 from "../../image/event/mothers/13M.jpeg";
import Mother14 from "../../image/event/mothers/14M.jpeg";
import Mother15 from "../../image/event/mothers/15M.jpeg";
import Mother19 from "../../image/event/mothers/19M.jpeg";
import Mother20 from "../../image/event/mothers/20M.jpeg";
import Mother21 from "../../image/event/mothers/21M.jpeg";
import Mother22 from "../../image/event/mothers/22M.jpeg";
import Mother23 from "../../image/event/mothers/23M.jpeg";
import Mother24 from "../../image/event/mothers/24M.jpeg";
import Mother25 from "../../image/event/mothers/25M.jpeg";
import Mother26 from "../../image/event/mothers/26M.jpeg";
import Mother27 from "../../image/event/mothers/27M.jpeg";
import Mother28 from "../../image/event/mothers/28M.jpeg";
import Mother29 from "../../image/event/mothers/29M.jpeg";
import Mother30 from "../../image/event/mothers/30M.jpeg";
import Mother31 from "../../image/event/mothers/31M.jpeg";
import Mother32 from "../../image/event/mothers/32M.jpeg";
import Mother33 from "../../image/event/mothers/33M.jpeg";
import Mother34 from "../../image/event/mothers/34M.jpeg";
import Mother35 from "../../image/event/mothers/35M.jpeg";
import Mother36 from "../../image/event/mothers/36M.jpeg";

export const GallarySlice = createSlice({
  name: "gallary",
  initialState: {
    gallary: [],
    loading: false,
    error: null,
    largeA4Image: [
      {
        id: 1,
        src: A4one,
        loading: "lazy",
      },
      {
        id: 2,
        src: A4Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: A4Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: A4Four,
        loading: "lazy",
      },
      {
        id: 5,
        src: A4Five,
        loading: "lazy",
      },
      {
        id: 6,
        src: A4Six,
        loading: "lazy",
      },
      {
        id: 7,
        src: A4Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: A4Eight,
        loading: "lazy",
      },
      {
        id: 9,
        src: A4Nine,
        loading: "lazy",
      },
      {
        id: 10,
        src: A4Ten,
        loading: "lazy",
      },
      {
        id: 11,
        src: A4Eleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: A4Twelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: A4Thirteen,
        loading: "lazy",
      },
      {
        id: 14,
        src: A4Forteen,
        loading: "lazy",
      },
    ],
    largeC4Image: [
      {
        id: 1,
        src: C4ones,
        loading: "lazy",
      },
      {
        id: 2,
        src: C4Twos,
        loading: "lazy",
      },
      {
        id: 3,
        src: C4Threes,
        loading: "lazy",
      },
      {
        id: 4,
        src: C4Fours,
        loading: "lazy",
      },
      {
        id: 5,
        src: C4Fives,
        loading: "lazy",
      },
      {
        id: 6,
        src: C4Sixs,
        loading: "lazy",
      },
      {
        id: 7,
        src: C4Sevens,
        loading: "lazy",
      },
      {
        id: 8,
        src: C4Eights,
        loading: "lazy",
      },
      {
        id: 9,
        src: C4Nines,
        loading: "lazy",
      },
      {
        id: 10,
        src: C4Tens,
        loading: "lazy",
      },
      {
        id: 11,
        src: C4Elevens,
        loading: "lazy",
      },
    ],
    motherDay: [
      {
        id: 1,
        src: MotherOne,
        loading: "lazy",
      },
      {
        id: 2,
        src: MotherTWe,
        loading: "lazy",
      },
      {
        id: 3,
        src: MotherThree,
        loading: "lazy",
      },
      {
        id: 4,
        src: MotherFour,
        loading: "lazy",
      },
      {
        id: 5,
        src: MotherFive,
        loading: "lazy",
      },
      {
        id: 6,
        src: MotherSix,
        loading: "lazy",
      },
      {
        id: 7,
        src: MotherSeven,
        loading: "lazy",
      },
      {
        id: 8,
        src: MotherEight,
        loading: "lazy",
      },
      {
        id: 9,
        src: MotherNine,
        loading: "lazy",
      },
      {
        id: 10,
        src: MotherTen,
        loading: "lazy",
      },
      {
        id: 11,
        src: MotherEleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: MotherTwelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: Mother13,
        loading: "lazy",
      },
      {
        id: 14,
        src: Mother14,
        loading: "lazy",
      },
      {
        id: 15,
        src: Mother15,
        loading: "lazy",
      },
      {
        id: 19,
        src: Mother19,
        loading: "lazy",
      },
      {
        id: 20,
        src: Mother20,
        loading: "lazy",
      },
      {
        id: 21,
        src: Mother21,
        loading: "lazy",
      },
      {
        id: 22,
        src: Mother22,
        loading: "lazy",
      },
      {
        id: 23,
        src: Mother23,
        loading: "lazy",
      },
      {
        id: 24,
        src: Mother24,
        loading: "lazy",
      },
      {
        id: 25,
        src: Mother25,
        loading: "lazy",
      },
      {
        id: 26,
        src: Mother26,
        loading: "lazy",
      },
      {
        id: 27,
        src: Mother27,
        loading: "lazy",
      },
      {
        id: 28,
        src: Mother28,
        loading: "lazy",
      },
      {
        id: 29,
        src: Mother29,
        loading: "lazy",
      },
      {
        id: 30,
        src: Mother30,
        loading: "lazy",
      },
      {
        id: 31,
        src: Mother31,
        loading: "lazy",
      },
      {
        id: 32,
        src: Mother32,
        loading: "lazy",
      },
      {
        id: 33,
        src: Mother33,
        loading: "lazy",
      },
      {
        id: 34,
        src: Mother34,
        loading: "lazy",
      },
      {
        id: 35,
        src: Mother35,
        loading: "lazy",
      },
      {
        id: 36,
        src: Mother36,
        loading: "lazy",
      },
    ],
    largeU3Image: [
      {
        id: 1,
        src: U3one,
        loading: "lazy",
      },
      {
        id: 2,
        src: U3Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: U3Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: U3Four,
        loading: "lazy",
      },
      {
        id: 5,
        src: U3Five,
        loading: "lazy",
      },
      {
        id: 6,
        src: U3Six,
        loading: "lazy",
      },
      {
        id: 7,
        src: U3Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: U3Eight,
        loading: "lazy",
      },
      {
        id: 9,
        src: U3Nine,
        loading: "lazy",
      },
      {
        id: 10,
        src: U3Ten,
        loading: "lazy",
      },
      {
        id: 11,
        src: U3Eleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: U3Twelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: U3Thirteen,
        loading: "lazy",
      },
      {
        id: 14,
        src: U3Forteen,
        loading: "lazy",
      },
    ],
    largeU5Image: [
      {
        id: 1,
        src: U5one,
        loading: "lazy",
      },
      {
        id: 2,
        src: U5Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: U5Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: U5Four,
        loading: "lazy",
      },
      {
        id: 5,
        src: U5Five,
        loading: "lazy",
      },
      {
        id: 6,
        src: U5Six,
        loading: "lazy",
      },
      {
        id: 7,
        src: U5Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: U5Eight,
        loading: "lazy",
      },
      {
        id: 9,
        src: U5Nine,
        loading: "lazy",
      },
      {
        id: 10,
        src: U5Ten,
        loading: "lazy",
      },
      {
        id: 11,
        src: U5Eleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: U5Twelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: U5Thirteen,
        loading: "lazy",
      },
      {
        id: 14,
        src: U5Forteen,
        loading: "lazy",
      },
    ],
    largeU8Image: [
      {
        id: 1,
        src: U8one,
        loading: "lazy",
      },
      {
        id: 2,
        src: U8Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: U8Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: U8Four,
        loading: "lazy",
      },
      {
        id: 5,
        src: U8Five,
        loading: "lazy",
      },
      {
        id: 6,
        src: U8Six,
        loading: "lazy",
      },
      {
        id: 7,
        src: U8Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: U8Eight,
        loading: "lazy",
      },
      {
        id: 9,
        src: U8Nine,
        loading: "lazy",
      },
      {
        id: 10,
        src: U8Ten,
        loading: "lazy",
      },
      {
        id: 11,
        src: U8Eleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: U8Twelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: U8Thirteen,
        loading: "lazy",
      },
      {
        id: 14,
        src: U8Forteen,
        loading: "lazy",
      },
    ],
    largeU4Image: [
      {
        id: 1,
        src: U4one,
        loading: "lazy",
      },
      {
        id: 2,
        src: U4Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: U4Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: U4Four,
        loading: "lazy",
      },
      {
        id: 5,
        src: U4Five,
        loading: "lazy",
      },
      {
        id: 6,
        src: U4Six,
        loading: "lazy",
      },
      {
        id: 7,
        src: U4Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: U4Eight,
        loading: "lazy",
      },
      {
        id: 9,
        src: U4Nine,
        loading: "lazy",
      },
      {
        id: 10,
        src: U4Ten,
        loading: "lazy",
      },
      {
        id: 11,
        src: U4Eleven,
        loading: "lazy",
      },
      {
        id: 12,
        src: U4Twelve,
        loading: "lazy",
      },
      {
        id: 13,
        src: U4Thirteen,
        loading: "lazy",
      },
      {
        id: 14,
        src: U4Forteen,
        loading: "lazy",
      },
    ],
    largeU7Image: [
      {
        id: 1,
        src: U7one,
        loading: "lazy",
      },
      {
        id: 2,
        src: U7Two,
        loading: "lazy",
      },
      {
        id: 3,
        src: U7Three,
        loading: "lazy",
      },
      {
        id: 4,
        src: U7Four,
        loading: "lazy",
      },
      {
        id: 7,
        src: U7Seven,
        loading: "lazy",
      },
      {
        id: 8,
        src: U7Eight,
        loading: "lazy",
      },
    ],
  },
  reducers: {
    fetchGallaryStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchGallarySuccess: (state, action) => {
      console.log(action.payload);
      state.loading = false;
      if (action.payload === "Peridot") {
        state.gallary = [
          {
            id: 1,
            src: A4one,
            loading: "lazy",
          },
          {
            id: 2,
            src: A4Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: A4Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: A4Four,
            loading: "lazy",
          },
          {
            id: 5,
            src: A4Five,
            loading: "lazy",
          },
          {
            id: 6,
            src: A4Six,
            loading: "lazy",
          },
          {
            id: 7,
            src: A4Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: A4Eight,
            loading: "lazy",
          },
          {
            id: 9,
            src: A4Nine,
            loading: "lazy",
          },
          {
            id: 10,
            src: A4Ten,
            loading: "lazy",
          },
          {
            id: 11,
            src: A4Eleven,
            loading: "lazy",
          },
          {
            id: 12,
            src: A4Twelve,
            loading: "lazy",
          },
          {
            id: 13,
            src: A4Thirteen,
            loading: "lazy",
          },
          {
            id: 14,
            src: A4Forteen,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "Topaz") {
        state.gallary = [
          {
            id: 1,
            src: C4ones,
            loading: "lazy",
          },
          {
            id: 2,
            src: C4Twos,
            loading: "lazy",
          },
          {
            id: 3,
            src: C4Threes,
            loading: "lazy",
          },
          {
            id: 4,
            src: C4Fours,
            loading: "lazy",
          },
          {
            id: 5,
            src: C4Fives,
            loading: "lazy",
          },
          {
            id: 6,
            src: C4Sixs,
            loading: "lazy",
          },
          {
            id: 7,
            src: C4Sevens,
            loading: "lazy",
          },
          {
            id: 8,
            src: C4Eights,
            loading: "lazy",
          },
          {
            id: 9,
            src: C4Nines,
            loading: "lazy",
          },
          {
            id: 10,
            src: C4Tens,
            loading: "lazy",
          },
          {
            id: 11,
            src: C4Elevens,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "White Cloud") {
        state.gallary = [
          {
            id: 1,
            src: U3one,
            loading: "lazy",
          },
          {
            id: 2,
            src: U3Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: U3Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: U3Four,
            loading: "lazy",
          },
          {
            id: 5,
            src: U3Five,
            loading: "lazy",
          },
          {
            id: 6,
            src: U3Six,
            loading: "lazy",
          },
          {
            id: 7,
            src: U3Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: U3Eight,
            loading: "lazy",
          },
          {
            id: 9,
            src: U3Nine,
            loading: "lazy",
          },
          {
            id: 10,
            src: U3Ten,
            loading: "lazy",
          },
          {
            id: 11,
            src: U3Eleven,
            loading: "lazy",
          },
          {
            id: 12,
            src: U3Twelve,
            loading: "lazy",
          },
          {
            id: 13,
            src: U3Thirteen,
            loading: "lazy",
          },
          {
            id: 14,
            src: U3Forteen,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "Sizzling Bliss") {
        state.gallary = [
          {
            id: 1,
            src: U7one,
            loading: "lazy",
          },
          {
            id: 2,
            src: U7Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: U7Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: U7Four,
            loading: "lazy",
          },
          {
            id: 7,
            src: U7Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: U7Eight,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "Diamond Edge") {
        state.gallary = [
          {
            id: 1,
            src: U5one,
            loading: "lazy",
          },
          {
            id: 2,
            src: U5Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: U5Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: U5Four,
            loading: "lazy",
          },
          {
            id: 5,
            src: U5Five,
            loading: "lazy",
          },
          {
            id: 6,
            src: U5Six,
            loading: "lazy",
          },
          {
            id: 7,
            src: U5Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: U5Eight,
            loading: "lazy",
          },
          {
            id: 9,
            src: U5Nine,
            loading: "lazy",
          },
          {
            id: 10,
            src: U5Ten,
            loading: "lazy",
          },
          {
            id: 11,
            src: U5Eleven,
            loading: "lazy",
          },
          {
            id: 12,
            src: U5Twelve,
            loading: "lazy",
          },
          {
            id: 13,
            src: U5Thirteen,
            loading: "lazy",
          },
          {
            id: 14,
            src: U5Forteen,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "The Nest") {
        state.gallary = [
          {
            id: 1,
            src: U8one,
            loading: "lazy",
          },
          {
            id: 2,
            src: U8Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: U8Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: U8Four,
            loading: "lazy",
          },
          {
            id: 5,
            src: U8Five,
            loading: "lazy",
          },
          {
            id: 6,
            src: U8Six,
            loading: "lazy",
          },
          {
            id: 7,
            src: U8Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: U8Eight,
            loading: "lazy",
          },
          {
            id: 9,
            src: U8Nine,
            loading: "lazy",
          },
          {
            id: 10,
            src: U8Ten,
            loading: "lazy",
          },
          {
            id: 11,
            src: U8Eleven,
            loading: "lazy",
          },
          {
            id: 12,
            src: U8Twelve,
            loading: "lazy",
          },
          {
            id: 13,
            src: U8Thirteen,
            loading: "lazy",
          },
          {
            id: 14,
            src: U8Forteen,
            loading: "lazy",
          },
        ];
      } else if (action.payload === "Grandeur") {
        state.gallary = [
          {
            id: 1,
            src: U4one,
            loading: "lazy",
          },
          {
            id: 2,
            src: U4Two,
            loading: "lazy",
          },
          {
            id: 3,
            src: U4Three,
            loading: "lazy",
          },
          {
            id: 4,
            src: U4Four,
            loading: "lazy",
          },
          {
            id: 5,
            src: U4Five,
            loading: "lazy",
          },
          {
            id: 6,
            src: U4Six,
            loading: "lazy",
          },
          {
            id: 7,
            src: U4Seven,
            loading: "lazy",
          },
          {
            id: 8,
            src: U4Eight,
            loading: "lazy",
          },
          {
            id: 9,
            src: U4Nine,
            loading: "lazy",
          },
          {
            id: 10,
            src: U4Ten,
            loading: "lazy",
          },
          {
            id: 11,
            src: U4Eleven,
            loading: "lazy",
          },
          {
            id: 12,
            src: U4Twelve,
            loading: "lazy",
          },
          {
            id: 13,
            src: U4Thirteen,
            loading: "lazy",
          },
          {
            id: 14,
            src: U4Forteen,
            loading: "lazy",
          },
        ];
      } else {
        state.gallary = [];
      }
    },
    fetchGallaryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchGallaryStart, fetchGallarySuccess, fetchGallaryFailure } =
  GallarySlice.actions;

export default GallarySlice.reducer;
