import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import copy from "copy-to-clipboard";

const NavBar = styled.nav`
  button {
    font-size: 12px;
    cursor: pointer;
    font-family: "Circular Std";
    width: auto;
    height: auto;
    padding: 5px 5px 5px 5px;
    color: white;
    background: linear-gradient(
        270deg,
        rgba(33, 147, 176, 0) 0%,
        #17677b 0%,
        #1c7b93 28.87%,
        #2193b0 100%
      ),
      linear-gradient(0deg, #1c7b93, #1c7b93);
    background: var(--color-primary);
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  .shows {
    position: absolute;
    font-size: 12px;
    width: 70px;
    margin-top: 20px;
    padding-top: 8px;
    height: auto;
    background: white;
    border: 1px solid gray;
    border-radius: 4px;
    text-align: center;
    p {
      cursor: pointer;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
`;

const MpPopup = () => {
  const [showCurrency, setShowCurrency] = useState(false);
  const referral_code = localStorage.getItem("referral_code");
  const refOne = useRef(null);

  const handelGenerate = (e) => {
    setShowCurrency(!showCurrency);
  };
  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  const handelePrint = (e) => {
    var divContents = document.getElementById("GFG").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html>");
    a.document.write("<body > <h1> Reference code: ");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
    setShowCurrency(!showCurrency);
  };
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setShowCurrency(false);
    }
  };

  const copyToClipboard = () => {
    copy(referral_code);
    alert("You have copied the Reference code to your clipboard");
    setShowCurrency(!showCurrency);
  };
  return (
    <NavBar>
      <button id="GFG" onClick={handelGenerate}>
        {referral_code}
      </button>
      <div ref={refOne}>
        {showCurrency && (
          <div className="shows">
            <p onClick={handelePrint}>
              <b>Print </b>
            </p>
            <p onClick={copyToClipboard}>
              <b> Copy</b>
            </p>
          </div>
        )}
      </div>
    </NavBar>
  );
};

export default MpPopup;
