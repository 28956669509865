import { configureStore } from "@reduxjs/toolkit";
import componentState from "./actions/componentState";
import formState from "./actions/formState";
import propertyResult from "./actions/propertyResult";
import propertyDetails from "./actions/propertyDetails";
import propertyDetails2 from "./actions/propertyDetails2";
import reservation from "./actions/reservation";
import reservation2 from "./actions/reservation2";
import PaymentsReducer from "./actions/payment";
import CustomerRecords from "./actions/saveCustomerRecord";
import AdminDashboard from "./actions/adminDashboard";
import CustomerSupport from "./actions/customerSupport";
import GallarySlice from "./actions/gallery";
import HomeReducer from "./actions/home";
import ComingSoonReducer from "./actions/comingSoonReducer";

export const store = configureStore({
  reducer: {
    homeReducer: HomeReducer,
    ComponentState: componentState,
    formState: formState,
    propertyResult: propertyResult,
    propertyDetails: propertyDetails,
    propertyDetails2: propertyDetails2,
    reservationState: reservation,
    reservationState2: reservation2,
    paymentState: PaymentsReducer,
    customerRecord: CustomerRecords,
    adminDashboard: AdminDashboard,
    customerSupport: CustomerSupport,
    gallary: GallarySlice,
    comingsoonReducer: ComingSoonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
