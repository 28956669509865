import {
  Login,
  AdminDashboard,
  NotFound,
  Home,
  AdminLogin,
  SearchResult,
  PropertyDetails,
  Payments,
  OrderSummary,
  Verify,
  Transfer,
  Gallery,
  MobileReservation,
  About,
  Register,
  PropertyHosted,
  CustomerService,
  TermsandPolicy,
  ListingHome,
  ListingProfile,
  Policy,
  ListingDashboard,
  ValueAdded,
  DriversForm,
  DriversHistory,
  Bookings,
  History,
  Listing,
  Notification,
  Listing2,
  Listing3,
  Listing4,
  ListingSummary,
  SendRefernce,
  Listing5,
  GalleryImages,
  Agency,
  VeriflyEmail,
  AdminHome,
  ComingPage,
  PromoPage,
  ComingPageDetails,
  PropertyDetailsPromo,
  ForgetPassword,
  ForgetPin,
  UpdatePassword,
  ProfilesMp,
  HistoryDetails,
  CompleteListing,
  Listing1,
  ListingEditProfile,
  UpdateBookingslist,
  PropertyRatingfull,
} from "./export";

const routes = (isLoggedIn, Query, user) => [
  {
    path: "/admin/live",
    element: isLoggedIn ? <AdminDashboard /> : <AdminLogin />,
    children: [
      { path: "home", element: <AdminHome /> },
      { path: "pending", element: <AdminDashboard /> },
      { path: "completed", element: <AdminDashboard /> },
      { path: "deleted", element: <AdminDashboard /> },
      { path: "complains", element: <AdminDashboard /> },
      { path: "update-booking", element: <AdminDashboard /> },
      { path: "register-user", element: <AdminDashboard /> },
      { path: "profile", element: <AdminDashboard /> },
      { path: "update-guest", element: <AdminDashboard /> },
      { path: "listing-request", element: <AdminDashboard /> },
      { path: "driver-history", element: <AdminDashboard /> },
    ],
  },
  {
    path: "/login",
    element: <Login />,
    children: [{ path: "login", element: <Login /> }],
  },
  {
    path: "/history",
    element: <History />,
    children: [{ path: "history", element: <History /> }],
  },
  {
    path: "/history/details",
    element: <HistoryDetails />,
    children: [{ path: ":id", element: <HistoryDetails /> }],
  },
  {
    path: "/bookings",
    element: <Bookings />,
    children: [{ path: "bookings", element: <Bookings /> }],
  },
  {
    path: "/profile-mp",
    element: <ProfilesMp />,
    children: [{ path: "bookings", element: <ProfilesMp /> }],
  },
  {
    path: "/sendref",
    element: <SendRefernce />,
    children: [{ path: "sendref", element: <SendRefernce /> }],
  },
  {
    path: "/verifly-email",
    element: <VeriflyEmail />,
    children: [{ path: "login", element: <VeriflyEmail /> }],
  },
  {
    path: "/listing-index",
    element: <ListingHome />,
    children: [{ path: "listing", element: <ListingHome /> }],
  },
  {
    path: "/listing",
    element: <Listing />,
    children: [{ path: "listing", element: <Listing /> }],
  },
  {
    path: "/listing1",
    element: <Listing1 />,
    children: [{ path: "listing1", element: <Listing1 /> }],
  },
  {
    path: "/listing2",
    element: <Listing2 />,
    children: [{ path: "listing2", element: <Listing2 /> }],
  },
  {
    path: "/listing3",
    element: <Listing3 />,
    children: [{ path: "listing3", element: <Listing3 /> }],
  },
  {
    path: "/listing4",
    element: <Listing4 />,
    children: [{ path: "listing4", element: <Listing4 /> }],
  },
  {
    path: "/listing5",
    element: <Listing5 />,
    children: [{ path: "listing5", element: <Listing5 /> }],
  },
  {
    path: "/listing-summary",
    element: <ListingSummary />,
    children: [{ path: "listing-summary", element: <ListingSummary /> }],
  },
  {
    path: "/listing-complete",
    element: <CompleteListing />,
    children: [{ path: "listing-summary", element: <CompleteListing /> }],
  },
  {
    path: "/listing-dashboard",
    element: <ListingDashboard />,
    children: [{ path: "/listing-dashboard", element: <ListingDashboard /> }],
  },
  {
    path: "/listing-dashboard-property",
    element: <PropertyHosted />,
    children: [{ path: ":Id", element: <PropertyHosted /> }],
  },

  {
    path: "/listing-profile",
    element: <ListingProfile />,
    children: [{ path: "/listing-profile", element: <ListingProfile /> }],
  },
  {
    path: "/notification",
    element: <Notification />,
    children: [{ path: "/notification", element: <Notification /> }],
  },
  {
    path: "/listing-profile-edit",
    element: <ListingEditProfile />,
    children: [
      { path: "/listing-profile-edit", element: <ListingEditProfile /> },
    ],
  },
  {
    path: "/listing-update-Bookings",
    element: <UpdateBookingslist />,
    children: [
      { path: "/listing-update-Bookings", element: <UpdateBookingslist /> },
    ],
  },
  {
    path: "/register",
    element: <Register />,
    children: [{ path: "/register", element: <Register /> }],
  },
  {
    path: "/forget-pin",
    element: <ForgetPin />,
    children: [{ path: "/forget-pin", element: <ForgetPin /> }],
  },
  {
    path: "/update-password",
    element: <UpdatePassword />,
    children: [{ path: "/update-password", element: <UpdatePassword /> }],
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
    children: [{ path: "/forget-password", element: <ForgetPassword /> }],
  },
  {
    path: "/",
    element: <Home />,
    children: [{ path: "/", element: <Home /> }],
  },
  {
    path: "/promopage",
    element: <PromoPage />,
    children: [{ path: "/promopage", element: <PromoPage /> }],
  },
  {
    path: "/drivers-form",
    element: <DriversForm />,
    children: [{ path: "/drivers-form", element: <DriversForm /> }],
  },
  {
    path: "/drivers-history",
    element: <DriversHistory />,
    children: [{ path: "/drivers-history", element: <DriversHistory /> }],
  },
  {
    path: "/promopage",
    element: <PromoPage />,
    children: [{ path: "/promopage", element: <PromoPage /> }],
  },
  {
    path: "/s/:id",
    element: <SearchResult />,
    children: [{ path: "s/:id", element: <SearchResult /> }],
  },
  {
    path: "/apartment/:Id",
    element: <PropertyDetails />,
    children: [{ path: ":Id", element: <PropertyDetails /> }],
  },
  {
    path: "/apartmentpromo",
    element: <PropertyDetailsPromo />,
    children: [{ path: ":Id", element: <PropertyDetailsPromo /> }],
  },
  {
    path: "/payment",
    element: <Payments />,
    children: [{ path: "payment", element: <Payments /> }],
  },

  {
    path: "/order-summary/ref",
    element: <OrderSummary />,
    children: [{ path: ":id", element: <OrderSummary /> }],
  },
  {
    path: "/order-summary",
    element: <Transfer />,
    children: [{ path: "payment", element: <Transfer /> }],
  },
  {
    path: "/payment-status",
    element: <Verify />,
    children: [{ path: "shortlet", element: <Verify /> }],
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/gallery/:id",
    element: <GalleryImages />,
  },
  {
    path: "/admin/live/login",
    element: <AdminLogin />,
  },
  {
    path: "/reservation",
    element: <MobileReservation />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/customer-support",
    element: <CustomerService />,
  },
  {
    path: "/policy",
    element: <TermsandPolicy />,
  },
  {
    path: "/terms",
    element: <Policy />,
  },
  {
    path: "/value-added-services",
    element: <ValueAdded />,
  },
  {
    path: "*",
    element: <NotFound />,
    children: [{ path: "*", element: <NotFound /> }],
  },
  {
    path: "/agency",
    element: <Agency />,
  },
  {
    path: "/coming-soon",
    element: <ComingPage />,
  },
  {
    path: "/coming-soon/apartment/:id",
    element: <ComingPageDetails />,
  },
  {
    path: "/rating",
    element: <PropertyRatingfull />,
    children: [{ path: ":Id", element: <PropertyRatingfull /> }],
  },
];

export default routes;
