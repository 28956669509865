import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./MenuItem";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MpPopup from "../components/mppopup";
const LI = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const variantds = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Ul = styled(motion.ul)`
  padding: 25px;
  position: absolute;
  top: ${({ top }) => top};
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
`;

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
const usertype = localStorage.getItem("usertype") || null;
const logoutHandler = () => {
  localStorage.clear();
  window.setTimeout(() => {
    window.location.href = "/login";
  }, 1000);
};
export const Navigation = ({ toggleOpen, isOpen, itemIds, top }) => (
  <Ul variants={variants} isOpen={isOpen} top={top}>
    {usertype === "MP" ? <MpPopup /> : null}

    <br></br>
    {itemIds?.map((item) => (
      <MenuItem
        i={item?.id}
        key={item?.id}
        data={item}
        toggleOpen={toggleOpen}
        isOpen={isOpen}
      />
    ))}

    <LI
      variants={variantds}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Link>
        <p onClick={logoutHandler}>
          {usertype === "Host" ? "Logout" : usertype === "MP" ? "Logout" : ""}
        </p>
      </Link>
    </LI>
  </Ul>
);
