import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { MdMenu, MdAccountCircle } from "react-icons/md";
import { Link } from "react-router-dom";

const NavBar = styled.nav`
  font-weight: 600;
  button {
    font-size: 13px;
    font-weight: 600;
    font-family: "Circular Std";
    width: 65px;
    height: auto;
    padding: 5px -0px 5px 0px;
    color: gray;
    background: transparent;
    margin-top: 10px;
    border: 1px solid gray;
    border-radius: 4px;
  }
  .shows {
    position: absolute;
    font-size: 12px;
    width: 140px;
    margin-top: 10px;
    margin-left: -70px;
    padding-left: 8px;
    padding-top: 8px;
    height: auto;
    background: white;
    border: 1px solid gray;
    border-radius: 4px;
  }
  p {
    cursor: pointer;
    color: black;
    &:hover {
      color: var(--color-primary);
    }
  }
`;

const SmallMenu = () => {
  const [showCurrency, setShowCurrency] = useState(false);
  const refOne = useRef(null);
  const handelCurrentcy = () => {
    setShowCurrency(!showCurrency);
  };
  const logoutHandler = () => {
    localStorage.clear();
    window.setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const name = localStorage.getItem("firstname");
  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setShowCurrency(false);
    }
  };
  return (
    <NavBar>
      <button onClick={handelCurrentcy}>
        <MdMenu size="25px" /> <MdAccountCircle size="25px" />
      </button>
      {showCurrency && (
        <div className="shows" ref={refOne}>
          <Link to="/listing-profile">
            <p>{name}'s Profile</p>
          </Link>
          <Link to="/listing-dashboard">
            <p>Manage Property</p>
          </Link>
          <p onClick={logoutHandler}>Logout</p>
        </div>
      )}
    </NavBar>
  );
};

export default SmallMenu;
