import { lazy } from "react";

export const Home = lazy(() => import("./Pages/Home/Home"));
export const SearchResult = lazy(() =>
  import("./Pages/SearchResult/SearchResult")
);
export const PropertyDetails = lazy(() =>
  import("./Pages/PropertyDetails/PropertyDetails")
);
export const PropertyDetailsPromo = lazy(() =>
  import("./Pages/PropertyDetails/PropertyDetailsPromo")
);
export const Payments = lazy(() => import("./Pages/payments/Payments"));
export const Reservation = lazy(() =>
  import("./Pages/payments/ReservationRight.jsx")
);
export const OrderSummary = lazy(() => import("./Pages/payments/OrderSummary"));
export const Verify = lazy(() => import("./Pages/payments/Verify.jsx"));
export const Transfer = lazy(() => import("./Pages/payments/transfer.jsx"));
export const AdminDashboard = lazy(() => import("./Pages/dashboard/Admin"));
export const Listing = lazy(() => import("./Pages/Listing/listing"));
export const Listing1 = lazy(() => import("./Pages/Listing/listing1"));
export const Listing2 = lazy(() => import("./Pages/Listing/listing2"));
export const ListingSummary = lazy(() =>
  import("./Pages/Listing/listingSummary")
);
export const ListingHome = lazy(() => import("./Pages/Listing/index"));
export const Listing3 = lazy(() => import("./Pages/Listing/listing3"));
export const Listing4 = lazy(() => import("./Pages/Listing/listing4"));
export const Listing5 = lazy(() => import("./Pages/Listing/listing5"));
export const AdminLogin = lazy(() =>
  import("./Pages/dashboard/components/Login")
);
export const Register = lazy(() => import("./Pages/Auth/register"));
export const CompleteListing = lazy(() => import("./Pages/Listing/complete"));
export const ForgetPassword = lazy(() => import("./Pages/Auth/forgetpassword"));

export const VeriflyEmail = lazy(() => import("./Pages/Auth/veriflyEmail"));
export const PropertyRatingfull = lazy(() =>
  import("./Pages/PropertyDetails/components/PropertyRatingfull")
);
export const ForgetPin = lazy(() => import("./Pages/Auth/forgetpin"));
export const DriversForm = lazy(() => import("./Pages/Drivers/drivers-form"));
export const DriversHistory = lazy(() =>
  import("./Pages/Drivers/drivers-history")
);
export const Bookings = lazy(() => import("./Pages/Partners/Booking"));
export const ListingDashboard = lazy(() =>
  import("./Pages/ListingDashboard/ListingDashboard")
);
export const ListingEditProfile = lazy(() =>
  import("./Pages/ListingDashboard/EditProfile")
);
export const UpdateBookingslist = lazy(() =>
  import("./Pages/ListingDashboard/updateGuest")
);
export const PropertyHosted = lazy(() =>
  import("./Pages/ListingDashboard/propertyhosted")
);
export const History = lazy(() => import("./Pages/Partners/History"));
export const Notification = lazy(() =>
  import("./Pages/ListingDashboard/notification")
);
export const HistoryDetails = lazy(() =>
  import("./Pages/Partners/HistoryDetails")
);
export const ProfilesMp = lazy(() => import("./Pages/Partners/Profile"));
export const ListingProfile = lazy(() =>
  import("./Pages/ListingDashboard/Profile")
);
export const SendRefernce = lazy(() => import("./Pages/Partners/SendRefernce"));
export const Login = lazy(() => import("./Pages/Auth/signin"));
export const UpdatePassword = lazy(() => import("./Pages/Auth/updatepassword"));
export const Gallery = lazy(() => import("./Pages/Gallery/Gallery"));
export const GalleryImages = lazy(() =>
  import("./Pages/Gallery/GalleryImages")
);
export const MobileReservation = lazy(() =>
  import("./Pages/payments/MobileReservation")
);
export const About = lazy(() => import("./Pages/About/About"));
export const CustomerService = lazy(() =>
  import("./Pages/Customer Support/CustomerSupport")
);
export const Policy = lazy(() => import("./Pages/Term and Policy/Policy"));
export const TermsandPolicy = lazy(() =>
  import("./Pages/Term and Policy/TermandPolicy.jsx")
);
export const ValueAdded = lazy(() => import("./Pages/Vas/ValueAdded"));
export const PromoPage = lazy(() => import("./Pages/Home/PromoPage"));
export const Agency = lazy(() => import("./Pages/Agency/Agency"));
export const AdminHome = lazy(() =>
  import("./Pages/dashboard/components/AdminHome")
);
export const ComingPage = lazy(() =>
  import("./Pages/Coming Soon/ComingResult")
);
export const ComingPageDetails = lazy(() =>
  import("./Pages/Coming Soon/ComingDetails")
);

export { default as Footer } from "./components/Footer/Footer";
export { default as NotFound } from "./components/NotFound";
export { default as Nav } from "./components/Nav/Nav";
