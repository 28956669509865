import { createSlice } from "@reduxjs/toolkit";
import { ShortletDetails2 } from "../actionCreators/actionCreators";

export const PropertyDetails2 = createSlice({
  name: "Shortlet2",
  initialState: {
    PropertyDetails2: {},
    pending: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ShortletDetails2.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(ShortletDetails2.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.PropertyDetails2 = action.payload;
      })
      .addCase(ShortletDetails2.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const { getShortletStart, getShortletSuccess, getShortletFailure } =
  PropertyDetails2.actions;

export default PropertyDetails2.reducer;
