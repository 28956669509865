import React from "react";
import { Link } from "react-router-dom";
import { Link as Links } from "react-scroll";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  CompanyLogo,
  CompanyLogoSmall,
  FilterIcon,
  HomeIcon,
} from "../../Svg/svg";
import Button from "../../components/Button/Button";
import {
  setOpenDrawer,
  setShowMobileReserveModal,
} from "../../redux/actions/componentState";
import useMediaQuery from "../../hooks/useMediaQuery/useMediaQuery";
import { AiOutlineMinus } from "react-icons/ai";
import { MainNav } from "./Dropdown/MainNav";
import { FlexStyle } from "../../styles/globalStyles";
import { motion, AnimatePresence } from "framer-motion";
import Currency from "./components/currentcy";
import SmallMenu from "./components/smallmenu";
import MpPopup from "./components/mppopup";

const NavBar = styled.nav`
  position: sticky;
  margin-top: -10px;
  padding-top: 1px;
  top: 0;
  z-index: 12;
  width: 100%;
  background: rgba(255, 255, 255, 0.72);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12);
  height: auto;

  .navContainer {
    width: 94%;
    margin: auto;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .navModalContent {
    @media (max-width: 850px) {
      display: none;
    }

    width: 44%;
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
    button {
      border: none;
      font-size: 16px;
      font-weight: 700;
      background: transparent;
      color: #1b1d28;
      @media (max-width: 950px) {
        font-size: 13px;
      }
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  .menue {
    @media (min-width: 860px) {
      display: none;
    }
  }
  .sigin {
    @media (max-width: 850px) {
      display: none;
    }
    width: 18%;
    display: flex;
    margin-top: 5px;
    justify-content: space-around;

    button {
      padding: 5px;
      padding-left: 12px;
      padding-right: 12px;
      font-size: 16px;
      border-radius: 3px;
      background: var(--color-primary);
      border: 2px solid black;
      border-color: var(--color-primary);
      color: white;
      @media (max-width: 950px) {
        font-size: 13px;
      }
      &:hover {
        background: transparent;
        color: var(--color-dark);
      }
    }

    .white-button {
      background: transparent;
      border-color: var(--color-dark);
      color: var(--color-dark);
      &:hover {
        background: var(--color-primary);
        color: white;
        border-color: transparent;
      }
    }
  }
  .hideprofile {
    @media (max-width: 840px) {
      display: none;
    }
  }
  .mobileMiniSearch {
    width: 100%;
    height: 45px;
    background: var(--color-light-gray);
    flex: 20;
    -webkit-box-flex: 20 !important;
    -webkit-flex: 20 !important;
    margin: 0 0.5rem;
    border-radius: 26px;
    width: 100%;
    padding: min(1rem, 0.3rem);
    cursor: pointer;
    div {
      ${FlexStyle};
      justify-content: center;
      height: 100%;
    }
    p {
      margin: 0;
      font-size: var(--font-small);
    }
    p:first-child {
    }
    p:nth-child(2) {
    }
    div:last-child {
      margin: 0 0 0 0.6rem;
    }
    svg {
      ${FlexStyle};
      font-size: 12px;
      margin: 0 0.2rem;
    }
  }
  .mobileHomeIcon {
    ${FlexStyle}
    svg {
      font-size: 1.2rem;
      color: var(--color-primary);
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: space-around !important;
    -webkit-justify-content: space-around !important;
    -moz-justify-content: space-around !important;
    -ms-justify-content: space-around !important;
    -o-justify-content: space-around !important;
  }
  .filterLink {
    font-size: 2rem;
  }
  @media screen and (max-width: 320px) {
    .mobileMiniSearch {
      display: none;
    }
  }
  .small {
    margin-top: 5px;
  }
`;

const Nav = () => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    localStorage.clear();
    window.setTimeout(() => {
      window.location.href = "/login";
    }, 1000);
  };

  const usertype = localStorage.getItem("usertype") || null;
  const {
    searchValue,
    useCheckOutDate,
    useCheckInDate,
    showMobileReserveButton,
    checkScroll,
  } = useSelector((state) => state.ComponentState);

  const ItemIds = [
    {
      id: 0,
      title: `${usertype === "MP" ? "Dashboard" : "List Your Property"}`,
      path: `${usertype === "MP" ? "/bookings" : "/listing-index"}`,
    },
    {
      id: 1,
      title: "Gallery",
      path: "/gallery",
    },
    {
      id: 2,
      titles: "Book An Apartment",
      path: "book",
    },

    {
      id: 3,
      title: `${
        usertype === "Host" ? "Profile" : usertype === "MP" ? "" : "Register"
      }`,
      path: `${
        usertype === "Host"
          ? "/listing-profile"
          : usertype === "MP"
          ? " "
          : "/register"
      }`,
    },
    {
      id: 4,
      title: `${
        usertype === "Host"
          ? "Manage Property"
          : usertype === "MP"
          ? " "
          : "Login"
      }`,
      path: `${
        usertype === "Host"
          ? "/listing-dashboard"
          : usertype === "MP"
          ? " "
          : "/login"
      }`,
    },
    {
      id: 5,
      title: "Contact",
      path: "/customer-support",
    },
  ];
  const URL = window.location.href;
  const newURL = URL.includes("location", "s");
  const ApartmentUrl = URL.includes("apartment");
  const Query = useMediaQuery("(min-width: 769px)");
  const Querys = useMediaQuery("(min-width: 569px)");
  const checKIn = useCheckInDate?.split(",")[1];
  const checkOut = useCheckOutDate?.split(",")[1];

  const handleDrawer = () => {
    dispatch(setOpenDrawer(true));
  };

  const showReserveModal = () => {
    dispatch(setShowMobileReserveModal(true));
  };

  return (
    <NavBar>
      <div className="navContainer">
        {Query ? (
          <div className="navModal">
            <Link to="/">{CompanyLogo}</Link>
          </div>
        ) : (
          <>
            {newURL ? (
              <div className="mobileHomeIcon">
                <Link to="/">{HomeIcon}</Link>
              </div>
            ) : (
              <div className="small">
                <Link to="/"> {CompanyLogoSmall}</Link>
              </div>
            )}
          </>
        )}

        {Query ? (
          <>{checkScroll && window.location.pathname === "/" ? "" : ""}</>
        ) : (
          <>
            {newURL ? (
              <div className="mobileMiniSearch">
                <div>
                  <p>{searchValue}</p>
                  <div>
                    <p>{checKIn}</p>
                    {checKIn && checkOut ? <AiOutlineMinus /> : null}
                    <p>{checkOut}</p>
                  </div>
                </div>
              </div>
            ) : (
              ApartmentUrl && (
                <>
                  <AnimatePresence initial={false}>
                    {showMobileReserveButton && (
                      <motion.div
                        className=""
                        style={{
                          display: "flex",
                          flex: "3",
                          marginLeft: "1rem",
                        }}
                        initial={{ opacity: 0, y: 70, scale: 0.5 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, y: 70, scale: 0.5 }}
                        transition={{
                          duration: 0.3,
                          type: {
                            type: "spring",
                          },
                        }}
                      >
                        <Button
                          title="Reserve"
                          disabledBG="var(--linear-primary)"
                          onClicks={showReserveModal}
                          border="none"
                          background="var(--linear-primary)"
                          color="var(--color-white)"
                          width="100%"
                          padding=".7rem"
                          fontSize="var(--font-xtra-small-screen)"
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </>
              )
            )}
          </>
        )}

        <div className="navModalContent">
          {window.location.pathname === "/" ? (
            <Links
              to="book"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              <button>Book An Apartment</button>
            </Links>
          ) : (
            <Link to="/">
              <button>Book An Apartment</button>
            </Link>
          )}

          {usertype === "Host" || usertype === "Temp_Host" ? (
            <Link to="/listing-dashboard">
              <button>Dashboard </button>
            </Link>
          ) : (
            ""
          )}
          <Link to="/listing-index">
            <button>List Your Property </button>
          </Link>
          <Link to="/gallery">
            <button>Gallery</button>
          </Link>
        </div>
        {Query ? <Currency /> : ""}

        {usertype === "MP" ? (
          <>
            {Querys ? <MpPopup /> : ""}

            <div className="sigin">
              <button onClick={logoutHandler}>Logout</button>
            </div>
          </>
        ) : usertype === "Host" || usertype === "Temp_Host" ? (
          <div className="hideprofile">
            <SmallMenu />
          </div>
        ) : usertype === "Guest" ? (
          <div className="hideprofile">
            <div className="sigin">
              <button onClick={logoutHandler}>Logout</button>
            </div>
          </div>
        ) : (
          <div className="sigin">
            <Link to="/register">
              <button className="white-button">Register</button>
            </Link>
            <Link to="/login">
              <button>Login</button>
            </Link>
          </div>
        )}

        <div className="menue">
          {Query ? (
            <MainNav ItemIds={ItemIds} top={"20px"} />
          ) : newURL ? (
            <Link to="#" className="filterLink" onClick={handleDrawer}>
              {FilterIcon}
            </Link>
          ) : showMobileReserveButton && ApartmentUrl ? (
            ""
          ) : (
            <>
              <MainNav ItemIds={ItemIds} top="20px" />
            </>
          )}
        </div>
      </div>
    </NavBar>
  );
};

export default Nav;
